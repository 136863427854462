import React from 'react'
import Slogan from './Slogan'
import Henhaoji from './Henhaoji'

export default function Home() {
  return (
    <div>
        <Slogan />
        <Henhaoji />
    </div>
  )
}
