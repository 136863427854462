import React from 'react';
import style from './index.module.css'
import backgroundImage from './background-image.jpg';

export default function Slogan() {
  return (
    <div className={style.container}>
      <img src={backgroundImage} alt="Background" className={style.image} />
      <p className={style.text}>设计驱动 极简创新</p>
    </div>
  );
}
