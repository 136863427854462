import React from 'react'
import style from './index.module.css'

export default function AboutUs() {
  return (
    <div className={style.container}>
        <h3>我们是谁？</h3>
        <p>我们是一家年轻的科技公司，致力于做一些我们需要的软件，同时希望可以造福世界。当前，我们正在开发一款名为“很好记”的 app，尚未在各大应用商店上线。</p>
        <p>我们的团队由一群年轻的、充满激情的人组成。我们相信代码可以改变世界，即便这个改变非常微小。而我们也希望通过自己的努力，为用户带来更多的便利。</p>
        <p>我们的产品将不断更新，力求为用户提供更好的体验，让用户的学习和工作更加简单和高效。我们也一直在不断探索和研究，希望能够将最先进的技术和用户的需求结合起来，为世界提供更多的可能性。</p>
        
        <h3>我们坚持怎样的价值观？</h3>
        <p>我们坚信，这个世界上永远有尚未被发现的市场需求。但并不是所有的需求都是我们想做的。</p>
        <p>我们只做个别细分领域，在这些领域做到绝对精彩。</p>
        <p>我们认为，公司的员工同客户一样重要。我们为员工提供一切法定保障和福利，同时也会提供公司自己的内部福利，保证员工的工作环境和工作心情。</p>
        <p>所以，对外优质服务，对内愉悦员工，是我们坚持的根本价值观。</p>

        <h3>想要加入我们？</h3>
        <p>我们十分期待可以聚贤纳才，一同为社会做出一点点贡献。</p>
        <p>但当前公司规模尚小，还未有稳定的保障。所以暂时不接受合作请求。十分抱歉。</p>
    </div>
  )
}
