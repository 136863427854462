import React from 'react';
import style from './index.module.css';
import screenshot from './screenshot.png'

export default function Henhaoji() {
  return (
    <div className={style.container}>
      <div className={style.intro}>
        <p className='h3'>很好记</p>
        <p className='small'>一个精巧又自由的艾宾浩斯记忆助手。</p>
        <a className='primary-button' href="https://henhaoji.nicotech.cc">
          了解更多
        </a>
      </div>
      <div className={style.screenshot}>
        <img src={screenshot} alt="App Screenshot" />
      </div>
    </div>
  );
}
