import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

export default function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <Link to="/">
          <img src="/images/logo.png" alt="" height={'40px'} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="http://henhaoji.nicotech.cc" className="nav-link ms-4">
                很好记
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us" className="nav-link ms-4">
                关于我们
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
