import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from './pages/Home'
import AboutUs from "./pages/AboutUs";
import './global.css'
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
